@import '../variables';

.container {
  margin: 0 auto;
  padding: 0 10px;
}

@if $fill-screens == 1 {
  .screen {
    &:nth-child(odd) {
      background: white;
    }
    &:nth-child(even) {
      background: whitesmoke;
    }
  }
}

$column: 80px;

@mixin column($c, $valign: top, $padding: true) {
  display: inline-block;
  vertical-align: $valign;
  width: $column * $c;
  @if $padding == true {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media only screen and (min-width: 980px) {
  .container {
    max-width: 980px;
  }
  .screen {
    padding: 55px 0;
    @if $show-grid == 1 {
      position: relative;
      &:before {
        content: '';
        opacity: 0.38;
        background: url('../../images/common/grid/960.png') repeat-y top center;
        position: absolute;
        left: 0;
        width: 100%;
        top: 0;
        height: 100%;
        z-index: 100;
      }
    }
  }
}

@media only screen and (min-width: 1400px) {
  .container {
    max-width: 1300px;
  }
  .screen {
    padding: 89px 0;
    @if $show-grid == 1 {
      position: relative;
      &:before {
        content: '';
        opacity: 0.38;
        background: url('../../images/common/grid/1280.png') repeat-y top center;
        position: absolute;
        left: 0;
        width: 100%;
        top: 0;
        height: 100%;
        z-index: 100;
      }
    }
  }
}

@media only screen and (max-width: 979px) {
  .container {
    max-width: 660px;
  }
  .screen {
    padding: 34px 0;
    @if $show-grid == 1 {
      position: relative;
      &:before {
        content: '';
        opacity: 0.38;
        background: url('../../images/common/grid/640.png') repeat-y top center;
        position: absolute;
        left: 0;
        width: 100%;
        top: 0;
        height: 100%;
        z-index: 100;
      }
    }
  }
}

@media only screen and (max-width: 660px) {
  .container {
    max-width: 100%;
  }
  .screen {
    padding: 34px 0;
    background: none;
    overflow: hidden;
    @if $show-grid == 1 {
      &:before {
        display: none;
      }
    }
  }
}
