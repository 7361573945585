@import 'src/style/variables';

.slick-prev,
.slick-next {
  background-repeat: no-repeat;
  width: 55px;
  height: 55px;
  margin-top: -30px;
  z-index: 100;
  background: transparent;
  border: 2px solid $main-color;
  border-radius: 28px;
  transition: background 300ms ease;
  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 30px;
    height: 55px;
    left: 50%;
    margin-left: -15px;
    top: 50%;
    margin-top: -27px;
    z-index: 10;
    background-image: url('arrows.png') !important;
  }
  &:hover {
    transition: background 300ms ease;
    background: $main-color;
  }
}
.slick-prev {
  left: -60px;
  &:before {
    background-position: left bottom !important;
  }
  &:after {
    background-position: left top !important;
  }
}
.slick-next {
  right: -60px;
  &:before {
    background-position: right bottom !important;
  }
  &:after {
    background-position: right top !important;
  }
}
.slick-prev,
.slick-next {
  &:before {
    opacity: 1;
  }
  &:after {
    opacity: 0;
  }
  &:hover {
    &:before {
      opacity: 0;
    }
    &:after {
      opacity: 1;
    }
  }
}
.slick-prev,
.slick-next {
  &:before,
  &:after {
    transition: opacity 300ms ease;
  }
  &:hover {
    &:before,
    &:after {
      transition: opacity 300ms ease;
    }
  }
}

@media only screen and (max-width: 979px) {
  .slick-prev {
    left: 5px;
  }
  .slick-next {
    right: 5px;
  }
}
