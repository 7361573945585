@import '../variables';
.link {
  display: inline-block;
  outline: none;
  text-decoration: none !important;
  border-bottom: 1px solid rgba(black, 0.03);
  color: $link-color;
  line-height: 1.5;
  &:hover {
    color: $link-color;
    border-color: $link-color;
    cursor: pointer;
  }
}
