$highlight-all-blocks: 0;
$show-grid: 0;
$fill-screens: 0;

$DEV: 0;
@if $DEV == 1 {
  $highlight-all-blocks: 1;
  $show-grid: 1;
  $fill-screens: 1;
}

// palette
$red: #da241a;
$main-color: $red;
$second-color: #374574;

// modals
$modal-close-color: $main-color;
$modal-close-hover-color: darken($main-color, 10%);

// buttons and links
$button-background: $main-color;
$button-shadow-color: #0062ae;
$callback-button-color: $main-color;
$link-color: $main-color;

// checkbox
$checkbox-background: #00b9f1;
$checkbox-size: 16px;

// slider
$slider-dot-color: $main-color;
$slider-dot-size: 20px;
$zoom-background: rgba($main-color, 0.62);

//
//
//

// teaser
$teaser-icon-size: 64px;

// benefits
$benefits-icon-circle-size: 128px;
