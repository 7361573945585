.slider {
  margin: 0;
  img {
    display: inline-block;
    max-width: 100%;
  }
}
.slide {
  border: none;
  box-shadow: none;
  border-radius: 0;
  margin: 0;
  outline: none;
}
