body {
  font-family: 'Open Sans', 'sans-serif';
}

h1 {
  font-weight: 400;
  margin: 0 0 2rem 0;
  padding: 0;
  line-height: 1;
  > b,
  > span {
    display: block;
  }
}

h2 {
  font-weight: 400;
  margin: 0 0 1em 0;
  padding: 0;
  line-height: 1.3;
  > b,
  > span {
    display: block;
  }
}

h3 {
  font-weight: 400;
  margin: 0 0 1em 0;
  padding: 0;
}
li {
  font-size: 16px;
}
p,
label {
  display: block;
  font-size: 16px;
  font-weight: 400;
  margin: 0 0 1em 0;
  padding: 0;
}
.nowrap {
  white-space: nowrap;
}
th,
td {
  font-size: 16px;
  padding: 0;
}
th {
  font-weight: 700;
}
td {
  font-weight: 400;
}

@media only screen and (min-width: 980px) {
  h1 {
    font-size: 42px;
  }
  h2 {
    font-size: 32px;
  }
  h3 {
    font-size: 21px;
  }
}

@media only screen and (max-width: 979px) {
  h1 {
    font-size: 26px;
  }
  h2 {
    font-size: 21px;
  }
  h3 {
    font-size: 18px;
  }
}
