* {
  box-sizing: border-box;
  &:before,
  &:after {
    box-sizing: border-box;
  }
}

html {
  font-size: 20px;
}

body {
  margin: 0;
  padding: 0;
  text-align: center;
  font-size: 0;
  overflow-x: hidden;
}

h3 {
  margin: 0;
  padding: 0;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
  > li {
    margin: 0;
    padding: 0;
  }
}

a,
a:hover,
a:active {
  outline: none;
  text-decoration: none;
  color: black;
}

button {
  display: inline-block;
  padding: 0;
  outline: none;
  border: none;
  background: none;
}
